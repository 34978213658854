/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import '~antd-button-color/dist/css/style.css';
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
body {
  height: 100%;
  overflow-y: hidden;
  background-color: #f8f8f8;
}
::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.margin-right {
  margin-right: 16px;
}
:global .ant-breadcrumb > span:last-child {
  color: #999;
  font-weight: normal;
}
:global .ant-breadcrumb-link .anticon + span {
  margin-left: 4px;
}
:global .ant-table .ant-table-thead > tr > th {
  text-align: center;
}
:global .ant-table .ant-table-tbody > tr > td {
  text-align: center;
}
:global .ant-table.ant-table-small .ant-table-thead > tr > th {
  background: #f7f7f7;
}
:global .ant-table.ant-table-small .ant-table-body > table {
  padding: 0;
}
:global .ant-table-pagination {
  float: none !important;
  display: table;
  margin: 16px auto !important;
}
:global .ant-popover-inner {
  border: none;
  border-radius: 0;
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
}
:global .ant-form-item-control {
  vertical-align: middle;
}
:global .ant-modal-mask {
  background-color: rgba(55, 55, 55, 0.2);
}
:global .ant-modal-content {
  box-shadow: none;
}
:global .ant-select-dropdown-menu-item {
  padding: 12px 16px !important;
}
:global a:focus {
  text-decoration: none;
}
:global .ant-table-layout-fixed table {
  table-layout: auto;
}
@media (min-width: 1600px) {
  :global .ant-col-xl-48 {
    width: 20%;
  }
  :global .ant-col-xl-96 {
    width: 40%;
  }
}
@media (max-width: 767px) {
  :global .ant-pagination-item,
  :global .ant-pagination-next,
  :global .ant-pagination-options,
  :global .ant-pagination-prev {
    margin-bottom: 8px;
  }
  :global .ant-card .ant-card-head {
    padding: 0 12px;
  }
  :global .ant-card .ant-card-body {
    padding: 12px;
  }
}
