.text--strong {
  font-weight: 600;
}
body {
  overflow-y: auto !important;
}
header#layoutHeader {
  position: fixed;
  width: -webkit-fill-available;
}
thead.ant-table-thead th {
  white-space: pre-wrap;
}
#root > section > section > aside > div {
  position: fixed;
  width: inherit;
  overflow-y: auto;
}
.ant-tag {
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
}
.ant-layout-sider-children {
  overflow-x: hidden;
}
.tox-notifications-container {
  display: none;
}
